import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

export const getOrderInvoiceById = async (order_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      query {
        orderInvoice(id: ${order_id}) {
          id
          Status
          Total
          Note
          created_at
          updated_at
          user {
            Details
            email
          }
          staged_invoices {
            create_user {
              Details
              id
              email
            }
          }
          customer {
            id
            FirstName
            LastName
            shipping_address {
              id
              Street
              Street2
              City
              State
              Zip
              Country
            }
            ConfirmationEmail
            Phone
            user {
              Details
              email
            }
          }
          order_invoice_payments {
            id
            Type
            Amount
          }
          orderInvoiceTrackings {
            id
            TrackingNumber
            TrackingUrl
            LineItems
            created_at
            updated_at
          }
          sales_orders {
            id
            LineItems
          }
          line_items {
            LineItemJson
            Quantity
            product {
              id
              Name
              Image {
                url
              }
            }
          }
          client{
            id
            acumatica
            AcumaticaSetting
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Build _or client filters to find orders
 * @param {string} search_term String to find
 * @param {int} client_id Client which you want to retrieve orders
 * @returns string
 */
function buildFiltersForClient(search_term, client_id) {
  let or_query =
    // Filters for client with shipping first name
    `{ customer: { FirstName_contains: "${search_term}" }, user: { clients: ${client_id} } }\n` +
    `{ customer: { FirstName_contains: "${search_term}", user: { clients: ${client_id} } } }\n` +
    `{ customer: { FirstName_contains: "${search_term}", client: ${client_id} } }\n` +
    `{ customer: { FirstName_contains: "${search_term}" }, client: ${client_id} }\n` +
    // Filters for client with shipping last name
    `{ customer: { LastName_contains: "${search_term}" }, user: { clients: ${client_id} } }\n` +
    `{ customer: { LastName_contains: "${search_term}", user: { clients: ${client_id} } } }\n` +
    `{ customer: { LastName_contains: "${search_term}", client: ${client_id} } }\n` +
    `{ customer: { LastName_contains: "${search_term}" }, client: ${client_id} }\n` +
    // Filters for client with customer
    `{ staged_invoices: { create_user: { Details_contains: "${search_term}" } }, user: { clients: ${client_id} } }\n` +
    `{ staged_invoices: { create_user: { Details_contains: "${search_term}" } }, customer: { user: { clients: ${client_id} } } }\n` +
    `{ staged_invoices: { create_user: { Details_contains: "${search_term}" } }, customer: { client: ${client_id} } }\n` +
    `{ staged_invoices: { create_user: { Details_contains: "${search_term}" } }, client: ${client_id} }\n` +
    // Filters for client with ordered by
    `{ user: { Details_contains: "${search_term}", clients: ${client_id} } }\n` +
    `{ user: { Details_contains: "${search_term}" }, customer: { user: { clients: ${client_id} } } }\n` +
    `{ user: { Details_contains: "${search_term}" }, customer: { client: ${client_id} } }\n` +
    `{ user: { Details_contains: "${search_term}" }, client: ${client_id} }\n`;
  return or_query;
}

/**
 * Build _or user filters to find orders
 * @param {string} search_term String to find
 * @param {int} users User from whom you want to retrieve orders
 * @returns string
 */
function buildFiltersForUser(search_term, user_id) {
  let or_query =
    // Filters for user with shipping first name
    `{ customer: { FirstName_contains: "${search_term}" } user: { id: ${user_id} } }\n` +
    `{ customer: { FirstName_contains: "${search_term}" } staged_invoices: { create_user: { id: ${user_id} } } }\n` +
    // Filters for user with shipping last name
    `{ customer: { LastName_contains: "${search_term}" } user: { id: ${user_id} } }\n` +
    `{ customer: { LastName_contains: "${search_term}" } staged_invoices: { create_user: { id: ${user_id} } } }\n` +
    // Filters for user with customer
    `{ staged_invoices: { create_user: { Details_contains: "${search_term}" } } user: { id: ${user_id} } }\n` +
    `{ staged_invoices: { create_user: { Details_contains: "${search_term}" id: ${user_id} } } }\n` +
    // Filters for user with ordered by
    `{ user: { Details_contains: "${search_term}" id: ${user_id} } }\n` +
    `{ user: { Details_contains: "${search_term}" } staged_invoices: { create_user: { id: ${user_id} } } }\n`;
  return or_query;
}

export const searchOrdersInvoices = async (values) => {
  // Clean search term
  let search_term = values.search_term.replace(/[^a-zA-Z0-9 ]/g, "").trim();
  const user_id = values.user_id;
  let order_id = "";
  if (!isNaN(search_term)) {
    order_id = parseInt(search_term);
  }
  //Find by FullName
  let FullName = [];
  if (search_term.includes(" ")) {
    FullName = search_term.split(" ");
    // Remove empty strings
    FullName = FullName.filter((name) => name !== "");
  }
  // Set headers
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  //get reseller id
  const reseller_id = localStorage.getItem("reseller_id");
  //get client id
  const client_id = localStorage.getItem("client_id");
  // Set client roles
  let client_roles = [
    "clientadmin",
    "clientproductmanager",
    "clientusermanager",
    "clientorderapprover",
  ];
  // Add filters
  let where = `where:{
    _publicationState: "preview",
    line_items: { product: { reseller: ${reseller_id} } }
    ${
      order_id
        ? `id_contains :[${order_id}]
        ${
          "role" in values && values.role && client_roles.includes(values.role)
            ? `_or: [
                { user: { clients: ${client_id} } } ,
                { customer: { user: { clients: ${client_id} } } },
                { customer: { client: ${client_id} } },
                { client: ${client_id} },
              ]`
            : user_id
            ? `_or: [
                { user: { id:  ${user_id} } }
                { staged_invoices: { create_user: { id:  ${user_id} } } }
              ]`
            : ``
        }`
        : ` _or:[
          ${
            FullName.length > 0
              ? FullName.map((word) =>
                  "role" in values &&
                  values.role &&
                  client_roles.includes(values.role)
                    ? buildFiltersForClient(word, client_id)
                    : user_id
                    ? buildFiltersForUser(word, user_id)
                    : `{ customer: { FirstName_contains: "${word}" } }\n` +
                      `{ customer: { LastName_contains: "${word}" } }\n` +
                      `{ staged_invoices: { create_user: { Details_contains: "${word}" } } }\n` +
                      ` { user: { Details_contains: "${word}" } }`
                )
              : "role" in values &&
                values.role &&
                client_roles.includes(values.role)
              ? buildFiltersForClient(search_term, client_id)
              : user_id
              ? buildFiltersForUser(search_term, user_id)
              : `{ customer: { FirstName_contains: "${search_term}" } }\n` +
                `{ customer: { LastName_contains: "${search_term}" } }\n` +
                `{ staged_invoices: { create_user: { Details_contains: "${search_term}" } } }\n` +
                ` { user: { Details_contains: "${search_term}" } }`
          }
        ]`
    }
  }`;
  axiosOptions.data = {
    query: `
    query{
      orderInvoices(
        sort: "id:DESC"
        ${where}
      ){
        id
        customer{
          id
          FirstName
          LastName
          ConfirmationEmail
          Phone
        }
        staged_invoices{
          create_user{
            id
            Details
          }
        }
        user{
          id
          Details
        }
        Status
        Total
        Note
        created_at
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    if (response.data.data && response.data.data.orderInvoices) {
      //Create search label
      response.data.data.orderInvoices.forEach((order) => {
        // Extract shipping info
        let name = order.customer !== null ? order.customer.FirstName : "";
        let lastname = order.customer !== null ? order.customer.LastName : "";
        // Customer info
        let customer =
          order.staged_invoices.length > 0 &&
          order.staged_invoices[0].create_user
            ? order.staged_invoices[0].create_user
            : null;
        let customer_name =
          customer && customer.Details ? customer.Details.FirstName : "";
        let customer_lastname =
          customer && customer.Details ? customer.Details.LastName : "";
        // Ordered by info
        let ordered_name =
          order.user && order.user.Details ? order.user.Details.FirstName : "";
        let ordered_lastname =
          order.user && order.user.Details ? order.user.Details.LastName : "";
        let search_label =
          `# ${order.id} - Shipping: ${name} ${lastname}` +
          ` - Customer: ${customer_name} ${customer_lastname} - Ordered: ${ordered_name} ${ordered_lastname}`;
        order.search_label = search_label;
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 *
 * @param {int} user_id Get orders from user
 * @param {int} offset Get orders with pagination
 * @param {object} filter
 * @param {object} client_filter
 * @param {bool} not_synced Flag to get orders not synced with Acumatica
 * @returns
 */
export const getLimitOrderInvoiceById = async (
  user_id,
  offset,
  filter,
  client_filter,
  date_filter,
  not_synced = false
) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  //let where = user_id ? `where:{user:[${user_id}]}` : "";
  let where = `where:{
    user:{${user_id ? `id:[${user_id}],` : ""} }
    ${
      date_filter.start
        ? `created_at_gt:"${Date.parse(date_filter.start)}"`
        : ""
    }
    ${date_filter.end ? `created_at_lt:"${Date.parse(date_filter.end)}"` : ""}

  
    ${
      client_filter
        ? `_or: [{ customer: { user: { clients: [${client_filter}] } } }, { client: ${client_filter} }]`
        : filter.client_id
        ? `_or: [{user:{clients:[${filter.client_id}]}},{client:{id:${filter.client_id}}}, { client: ${filter.client_id} }]`
        : filter.reseller_id
        ? `_or: [
          { customer: { user: { clients: { reseller: ${filter.reseller_id} } } } }
          { customer: { client: { reseller: ${filter.reseller_id} } } }
          { client: { reseller: ${filter.reseller_id} } }
        ]`
        : ""
    }
    ${not_synced ? "sales_orders_null: true" : ""}
  }`;

  axiosOptions.data = {
    query: `
      query{
        orderInvoices(
          limit:20,
          start:${offset},
          ${
            not_synced ||
            client_filter ||
            filter.client_id ||
            filter.reseller_id
              ? "publicationState: PREVIEW,"
              : ""
          }
          ${where},
          sort:"created_at:desc"
        ){
          id
          user{
            Details
            email
          }
          customer{
            id
            client{
              id
            }
            FirstName
            LastName
            shipping_address{
              id
              Street
              Street2
              City
              State
              Zip
              Country
            }
            ConfirmationEmail
            Phone
            user{
              Details
              email
            }
          }
          staged_invoices{
            create_user{
              Details
              id
              email
            }
          }
          Status
          Total
          Note
          created_at
        }
        ${
          client_filter
            ? `
          client( id: ${client_filter} ) {
            id
            acumatica
          }
        `
            : ""
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Send request to syncronize remaining orders with Acumatica
 * @param {object} values params (client_id, ...)
 * @returns Array
 */
export const sendOrdersToAcumatica = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  let reseller_id = localStorage.getItem("reseller_id");
  axiosOptions.data = {
    query: `
      mutation {
        sendOrdersToAcumatica(
          input: {
            data: {
              reseller_id: ${reseller_id},
              ${values.client_id ? "client_id: " + values.client_id : ""}
            }
          }
        ){
          id
          Status
          Total
          sales_orders{
            id
            ClientSubdomain
            AcumaticaSoNumber
          }
        }
      }    
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const createOrderInvoice = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  //Get client id
  const client_id = localStorage.getItem("client_id");

  axiosOptions.data = {
    query: `
      mutation {
        orderInvoice: createOrderInvoiceCardPayment(
          input: {
            data: {
              client: ${client_id}
              customer: ${values.customer_id}
              Total: ${values.total}
              Note: "${values.note ? values.note : "No notes"}"
              staged_invoices: ${values.staged_invoice_id}
              user:${values.user_id}
              ${
                values.credit_card_number
                  ? `credit_card_number: "${values.credit_card_number}"`
                  : ""
              }
              ${
                values.expiration_year
                  ? `expiration_year: "${values.expiration_year}"`
                  : ""
              }
              ${
                values.expiration_month
                  ? `expiration_month: "${values.expiration_month}"`
                  : ""
              }
              ${
                values.security_code
                  ? `security_code: "${values.security_code}"`
                  : ""
              }
            }
          }
        ) {
          id
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getOrderInvoiceByUser = async (user_id, userProfile = false) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  let where = userProfile
    ? `where:{
        _or:[
          { user: [${user_id}] }
          { staged_invoices: { create_user: { id: ${user_id} } } }
        ]
      }`
    : `where:{user:[${user_id}]}`;
  axiosOptions.data = {
    query: `
    query {
      orderInvoices(sort:"created_at:desc", ${where}) {
        id
        customer {
          id
          FirstName
          LastName
          billing_address {
            id
            Street
            Street2
            City
            State
            Zip
            Country
          }
          ConfirmationEmail
          Phone
        }
        Status
        Total
        Note
        created_at
        line_items {
          LineItemJson
        }
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    //Check if data is ok
    if (response.data && response.data.data.orderInvoices) {
      //Iterate orderInvoices
      response.data.data.orderInvoices.forEach((order) => {
        //Save created_at
        let created_at = order.created_at;
        //build date in format mm/dd/yyyy
        let date = new Date(created_at);
        //Get date in format DD/MM/YYYY
        let newdate =
          date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
        //Get hours in format 12 hours am/pm
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        let strTime = newdate + ", " + hours + ":" + minutes + " " + ampm;
        order.created_at = strTime;
      });
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getOrderInvoicesForApproval = async (
  client_id,
  approval_groups = []
) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    query {
      orderInvoices(
        sort: "created_at:desc"
        publicationState: PREVIEW
        where:{
          Status:"SubmittedForApproval",
          ${
            approval_groups.length > 0
              ? `staged_invoices: { create_user: { approval_groups_in: ${JSON.stringify(
                  approval_groups
                )} } }`
              : ``
          }
          _or: [
            { customer: { user: { clients: ${client_id} } } },
            { client: ${client_id} }
          ]
        }
      ) {
        id
        created_at
        Total
        customer{
          FirstName
          LastName
        }
        line_items{
          product{
            id
            Name
          }
        }
        user {
          Details
          email
        }
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateOrderInvoiceStatus = async (id, status) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation {
        updateOrderInvoice(
          input: {
            where: { id: ${id} }
            data: {
              Status: ${status}
            }
          }
        ) {
          orderInvoice {
            id
            Status
          }
        }
      }    
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const searchOrdersForApprovalByid = async (client_id, search_term) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    query {
      orderInvoices(
        sort: "created_at:desc"
        where:{
          Status:"SubmittedForApproval",
          customer: { user: { clients: ${client_id} } }
          id_contains: "${search_term}"
        }
      ) {
        id
        created_at
        Total
        customer{
          FirstName
          LastName
        }
        line_items{
          product{
            id
            Name
          }
        }
        user {
          Details
          email
        }
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
